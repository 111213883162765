<template>
    <div>
        <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
        <b-navbar toggleable="md" type="light" variant="light" class="nav-2">
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav ref="collapseNav2">
            <b-navbar-nav>
              <b-nav-item :to="{ name: 'home', hash: '#about_us' }">
                <img src="@/assets/img/users.light.svg">
                <label :class="{ 'active': $route.hash  == '#about_us'}">About Us</label>
              </b-nav-item>
              <b-nav-item :to="{ name: 'home', hash: '#our_creations' }">
                <img src="@/assets/img/favorite.light.svg">
                <label :class="{ 'active': $route.hash  == '#our_creations'}">Our Creations</label>
              </b-nav-item>
              <b-nav-item :to="{ name: 'home', hash: '#careers' }">
                <img src="@/assets/img/briefcase.light.svg">
                <label :class="{ 'active': $route.hash  == '#careers'}">Careers</label>
              </b-nav-item>
              <b-nav-item :to="{ name: 'home', hash: '#press' }">
                <img src="@/assets/img/file.alt.light.svg">
                <label :class="{ 'active': $route.hash  == '#press'}">Press</label>
              </b-nav-item>
              <b-nav-item :to="{ name: 'home', hash: '#contact_us' }">
                <img src="@/assets/img/mail.light.svg">
                <label :class="{ 'active': $route.hash  == '#contact_us'}">Contact Us</label>
              </b-nav-item>
              <b-nav-item>
                <router-link :to="{ name: 'login' }">
                  <b-button variant="outline-dark">Login</b-button>
                </router-link>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="container_news_room_detail">
            <b-row class="m-0">
                <b-col class="p-0" cols="12" xl="3" lg="3" md="12" sm="12" order-xl="1" order-lg="1" order-md="2" order-sm="2" order="2">
                    <div class="container_news_room_detail__column_interest">
                        <div class="container_news_room_detail__column_interest--sticky">
                            <b-button variant="default" @click="$router.push({ name: 'home', hash: '#press' })"><vue-fontawesome icon="chevron-left" size="1"></vue-fontawesome> Back</b-button>
                            <p class="card-text small text-muted p-2 text-center">
                                <a href="https://www.facebook.com/Do-Genius-On-LLC-110413997900290" target="_blank" style="margin-left: 0.5rem !important;">
                                    <img src="@/assets/img/facebook_logo.svg" alt="Facebook logo" style="width: 30px;">
                                </a>
                                <a href="https://www.instagram.com/do_genius_on/" target="_blank" style="margin-left: 0.5rem !important;">
                                    <img src="@/assets/img/instagram_logo.svg" style="width: 30px;">
                                </a>
                                <a href="https://www.linkedin.com/company/do-genius-on/" target="_blank" style="margin-left: 0.5rem !important;">
                                    <img src="@/assets/img/linkedin_logo.svg" style="width: 30px;">
                                </a>
                            </p>
                            <h4 class="p-3">
                                It may interest you
                            </h4>
                            <div v-for="(value, key) in posts" :key="key" class="link_interest" v-if="value.slug != post.slug">
                                <hr>
                                <router-link :to="{ name: 'news-room.slug', params: {slug: value.slug} }">{{value.title}}</router-link>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0" cols="12" xl="9" lg="9" md="12" sm="12" order-xl="2" order-lg="2" order-md="1" order-sm="1" order="1">
                    <div class="container_news_room_detail__column_information">
                        <b-button class="d-lg-none" variant="default" @click="$router.push({ name: 'home', hash: '#press' })">
                          <vue-fontawesome icon="chevron-left" size="1"></vue-fontawesome> Back
                        </b-button>
                        <b-row>
                            <b-col class="mt-3" cols="12" order-xl="1" order-lg="1" order-md="2" order-sm="2" order="2">
                                <h1 class="text-center mb-5" style="font-size: 32px;">
                                    {{post.title}}
                                </h1>
                                <h4 class="text-center mb-5" v-if="!post.subtitle">
                                    {{post.subtitle}}
                                </h4>
                            </b-col>
                            <b-col class="mt-3" cols="12" order-xl="2" order-lg="2" order-md="1" order-sm="1" order="1">
                                <b-img-lazy :src="post.image" fluid center :alt="post.alt_image"></b-img-lazy>
                            </b-col>
                        </b-row>
                        <caption class="mt-3">
                            <small>{{post.post_date_format}}</small>
                        </caption>
                        <p v-html="post.body" class="mt-3 mb-5"></p>
                        <hr>
                        <div class="container_news_room_detail__column_information--featured_ons">
                            <a :href="value.link" v-for="(value, key) in post.featured_posts" :key="key" target="_blank">
                              <b-avatar :src="addLogo(value.link)" :text="addAvatar(value.link)"></b-avatar>
                            </a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
export default {
  name: 'detail_post',
  components: {
    Footer
  },
  metaInfo(){
    return {
      title: this.post.meta_title,
      titleTemplate: '%s | MCSS',
      meta: this.post.meta_tags
    }
  },
  watch: {
    $route(route){
      this.getPost()
    },
  },
  data() {
    return {
      load: false,
      post: Object,
      posts: Object,
    };
  },
  mounted(){
    this.getPost();
    this.getPosts();
  },
  methods: {
    getPost(){
      this.load = true;
      this.$http.get(`posts/dgo/${this.$route.params.slug}`).then((response) => {
        if (response.status == 200) {
          this.post = response.body.data;
          this.post.meta_tags = this.post.meta_tags.map((mt) => ({name: mt.name, content: mt.content}));
        }
        this.load = false;
      }, (error) => {
        if (error.status == 404) {
          this.$router.push({ name: 'page.404' });
        }
      })
    },
    getPosts(){
      this.load = true;
      this.$http.get('posts/dgo').then((response) => {
        if (response.status == 200) {
          this.posts = response.body.data;
        }
        this.load = false;
      }, (error) => {
        console.log(error)
      })
    },
  },
};
</script>
