<template>
  <div class="container_footer">
    <div class="row container_footer__mobile">
      <div class="col-7 col-md-6 col-xl-3 container_footer__logo text-center">
        <router-link :to="{ name: 'home' }">
          <div class="row">
            <div class="col-4 col-md-3 p-0">
              <img src="@/assets/img/do-genius-on.svg"
                  class="img-fluid container_footer__logo--image">
            </div>
            <div class="col-8 p-0 container_footer__col-text">
              <h5>
                BUILDING <br>
                TECHNOLOGY <br>
                FOR TECHNOLOGY <br>
                BUILDERS
              </h5>
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-5 col-md-6 col-xl-3 order-xl-3 container_footer__social_media text-center">
        <a href="https://www.linkedin.com/company/do-genius-on" target="_blank">
          <vue-fontawesome icon="linkedin" size="2"></vue-fontawesome>
        </a>
        <a href="https://www.facebook.com/Do-Genius-On-LLC-110413997900290" target="_blank">
          <vue-fontawesome icon="facebook-square" size="2"></vue-fontawesome>
        </a>
      </div>
      <div class="col-12 col-xl-6 order-xl-2 container_footer__text text-center order ">
        <label>
          &copy; {{ currentYear }} <a href="https://dogeniuson.com/">dogeniuson.com</a> | Do Genius On, LLC. All rights reserved
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: null,
    };
  },
  mounted() {
    let year = new Date();
    this.currentYear = year.getFullYear();
  }
};
</script>
